// *****************************************************************
//JS: lovell-personalisation.js
// Lovell Rugby website functions for personalisation system
// (c) 2017 Lovell Rugby Limited

$(document).on("click", ".cancel-prsnl", function (e) {
  noPersnButton();
  return false;
});

$(document).on("click", ".confirm-prsnl", function (e) {
  LovellPersn._modalAcceptPersonalisation();
  return false;
});

// Personalisation text/select/checkbox inputs
window.LovellPersn = (function() {
  function persn() {
    var self = this;
    this.personalisationInputs = {};
    this.relationships         = {};
    this.serviceData           = {};

    this.setRelationships = function(relations) {
      self.relationships = relations || {};
    };

    // We're now receiving the whole Services structure from Perl, so some of the above (SoD images, length limits) could be nixed.
    // I still prefer the relationship structure we get passed down, and this value is currently JUST the services, so we may retain that.
    this.setServiceData = function(data) {
      self.serviceData = data || {};
    };

    /**
    * change event on persn inputs - re-check what should be enabled/disabled, and hide any error messages
    **/
    this._onPersnInputChange = function(e) {
      self._updateInputsStatus();
      $(".persn-message__invalid-persn").css("display", "none");
    };

    /**
    * Disables an input - handles special cases for SoD boxes which don't respond neatly
    **/
    this._setInputDisabled = function(serviceID, disableBool) {
      var input = self.personalisationInputs[serviceID];

      // Enable/disable the input
      input
        .attr("disabled", (disableBool ? "disabled" : false))
        [(!disableBool ? "add" : "remove") + "Class"]("persn-service__input--satisfied")

        // Likewise, we'll add a class to the parent wrapper for stylin
        .parent(".personalisation-input")
        [(!disableBool ? "add" : "remove") + "Class"]("personalisation-input--disabled");

      if (input[0].tagName.match(/select/i)) {
        input
        // Handle the <select>'s too, since SoD doesn't like doing this itself.
          .parents(".sod_select")
          [(disableBool ? "add" : "remove") + "Class"]("disabled");

        // Select the first <option> again
        if (disableBool)
          $(input[0].options[0]).prop("selected", true);
      }

    };

    /**
    * Returns the value of a selected input. Again, handles special cases for <select>'s
    **/
    this._getPersnInputVal = function(serviceID) {
      var input = self.personalisationInputs[serviceID];
      if (!input) {
        console.error("Invalid input for ServiceID " + serviceID + " // ", input);
        return "";
      }

      if (input.is(":disabled") || ((input.attr("type") || "").match(/radio|checkbox/i) && !input.is(":checked"))) return "";

      var val = input.val() || "";
      if (input[0].tagName.match(/select/i)) {
        if (val.toLowerCase() == "custom") {
          var customInput = $(".persn-service__custom-input-" + serviceID).slideDown();
          val = customInput.val();
        } else {
          // If the <option> value is BLANK we dont want to use the <option> text
          // Basically working around jQuery trying to be helpful here
          val = $(input[0].options[input[0].selectedIndex]).attr("value") || "";
        }
      } else if (input.attr("type").match(/text/i)) {
        // Uppercase text inputs here - we DISPLAY them as uppercase, but content could still be lower
        val = val.toUpperCase();
      }
      return val;
    };

    /**
    * Takes a service ID and option ID within that service, returns the {Option} object
    * including friendly text name and (TODO) translated data
    **/
    this._getServiceOption = function(serviceID, optionID) {
      if (!self.serviceData[serviceID]["FieldType"].match(/select/i)) return;
      var options = self.serviceData[serviceID]["Options"] || [];
      for (var i in options) {
        if (options[i]["ID"] == optionID) {
          return options[i];
        }
      }
    },

    /**
    * Test all the constraints and disable/enable inputs accordingly
    **/
    this._updateInputsStatus = function() {
      for (var serviceID in self.relationships) {
        var status = self.getServiceConstraintsSatisfiedBool(serviceID, ConstraintTestType.ENABLE);
        self._setInputDisabled(serviceID, !status);
      }
    };

    /**
    * Tests if the current state of the inputs is valid, returns a suitable error message if not, or false is everything's fine.
    **/
    this.getFormSubmitErrorMessage = function() {
      var failedService = this._getServiceFailingConstraint();

      if (failedService) {
        var error = "An unknown error occurred with your chosen personalisation";
        var mainServiceTitle = this.serviceData[failedService.ServiceID]['Title'];
        var relatedServiceTitle = this.serviceData[failedService.RelatedServiceID]['Title'];
        switch (failedService.RelationshipType.toUpperCase()) {
          case "REQUIRES":
            error = "You must enter " + relatedServiceTitle;
            break;
          case "MUTUAL":
            error = "You must enter " + relatedServiceTitle + " and " + mainServiceTitle;
            break;
          case "UNIQUE":
            error = "You can only choose one out of " + relatedTitle + " and " + mainServiceTitle;
            break;
        }

        return error;
      }
    return false;
    };

    /**
    * Tests all constraints and returns an object describing the first that fails, or false if everything works.
    **/
    this._getServiceFailingConstraint = function() {
      for (var serviceID in self.relationships) {
        var testResult = self.getServiceConstraintsSatisfied(serviceID, ConstraintTestType.SUBMIT);
        if (testResult !== true) return testResult;
      }
      return false;
    };

    /**
    * Returns boolean, if the given serviceID is satisfied
    **/
    this.getServiceConstraintsSatisfiedBool = function(serviceID, testType) {
      return (this.getServiceConstraintsSatisfied(serviceID, testType) === true);
    };

    /**
    * Tests all constraints, returns boolean true is everything is fine,
    **/
    this.getServiceConstraintsSatisfied = function(serviceID, testType) {
      if (!serviceID) return false;
      if (!self.relationships[serviceID]) return true;
      testType = testType || ConstraintTestType.SUBMIT;

      for (var relationshipType in self.relationships[serviceID]) {
        for (var serviceIndex in self.relationships[serviceID][relationshipType]) {
          var relatedServiceID = self.relationships[serviceID][relationshipType][serviceIndex];
          if (!self._getConstraintSatisfied(serviceID, relatedServiceID, relationshipType, testType)) {
            return {
              ServiceID: serviceID,
              RelatedServiceID: relatedServiceID,
              RelationshipType: relationshipType
            };
          };
        }
      }

      return true;
    };

    var ConstraintTestType = {
      ENABLE: 1,
      SUBMIT: 2
    };

    this._getConstraintSatisfied = function (serviceID, relatedService, relationshipType, testType) {
      if (!serviceID                    ||
        !relationshipType               ||
        !relatedService
      ) return false;

      relationshipType = relationshipType.toUpperCase();
      testType = testType || ConstraintTestType.SUBMIT;
      var value  = false;
      var test1  = self._getPersnInputVal(serviceID);
      var test2  = self._getPersnInputVal(relatedService);

      if (testType == ConstraintTestType.ENABLE) {
        value = this._getInputCanBeEnabled(test1, test2, relationshipType);
      } else if (testType == ConstraintTestType.SUBMIT) {
        value = this._getInputCanBeSubmitted(test1, test2, relationshipType);
      }

      return value;
    };

    this._getInputCanBeEnabled = function(test1, test2, constraintType) {
      var value = false;
      switch (constraintType) {
        case "REQUIRES":
          // This input requires the other to be filled
          value = (test2.length > 0);
          break;

        case "UNIQUE":
          // Only one of these inputs can be used -- either 1 or 2 is filled, or both are null
          value = (test2 == false);
          break;

        case "MUTUAL":
          // Inputs must be mutually filled or mutually null
          value = true;
          break;
      }

      return value;
    };

    this._getInputCanBeSubmitted = function(test1, test2, constraintType) {
      var value = false;
      switch (constraintType) {
        case "REQUIRES":
          // Requires doesn't matter
          value = true;
          break;

        case "UNIQUE":
          // Only one of these inputs can be used -- either 1 or 2 is filled, or both are null
          value = ( (test1 && !test2) || (!test1 && test2) || (!test1 && !test2) );
          break;

        case "MUTUAL":
          // Inputs must be mutually filled or mutually null
          value = ((test1 && test2) || (!test1 && !test2));
          break;
      }

      return value;
    };

    this.getServiceValues = function() {
      var persnDataObject = {};
      for (var serviceID in self.personalisationInputs) {
        var value = self._getPersnInputVal(serviceID);

        if (self.getServiceConstraintsSatisfied(serviceID) && value)
          persnDataObject[serviceID] = value;
      };

      return persnDataObject;
    };
    this.getServiceValuesString = function() {
      var values = this.getServiceValues();
      var keys = Object.keys(values).sort();
      var string = "";

      for (var i in keys) {
        string += keys[i] + ":" + values[keys[i]] + ",";
      }
      return string.replace(/,+$/, "");
    };

    this.showPersonalisationTermsModal = function() {
      var modal        = $("#prsnl-confirm-panel");
      var modalOptions = modal.find(".prsnl-confirm-panel__options");
      var modalSize    = modal.find(".confirm-field-size");
      var persnChosen  = [];

      var services     = self.getServiceValues();
      var serviceKeys  = Object.keys(services).sort();

      for (var i in serviceKeys) {
        var serviceID = serviceKeys[i];
        var value = services[serviceID];

        if (value.match(/^\d+$/)) {
          var fieldType = self.serviceData[serviceID].FieldType;
          if (fieldType.match(/select/i)) {
            var option = self._getServiceOption(serviceID, value);
            if (option) value = option["OptionText"];
          } else if (fieldType.match(/check/i)) {
            value = '<ion-icon name="checkmark-outline"></ion-icon>';
          }
        }

        persnChosen.push(
          $("<div>")
            .addClass("confirm-field confirm-field-" + (i + 1))
            .append(
              $("<div>")
                .css("text-align", "center")
                .addClass("small")
                .text(self.serviceData[serviceID]["Title"] + ":"),
              $("<span>")
                .html(value)
            )
        );
      }

      // Put values in modal, show
      modalOptions.html(
        $("<div>")
          .addClass("confirm-row")
          .append(persnChosen)
      );

      // NOTE this wont play nice with bundles
      modalSize.text($("#ba_Size").val());
      modal.modal();
    };

    this._modalAcceptPersonalisation = function() {
      $("#prsnl-confirm-panel").modal("hide");
      var page;
      if (window.location.href.match( /\/(update|show)?basket\b/)){
        page = "BASKET_PAGE";
      }
      addProductToBasket(page);
    };

    this._checkTextInput = function(string, element) {
      element = (element ? $(element) : false);

      // Test the allowed characters
      var regexMatch = (element && element.attr("type").match(/number/i) ? "0-9" : "a-z0-9\\s\\.\\'");
      var regex = new RegExp( "^[" + regexMatch + "]*$", "i" );
      if (!regex.test(string)) {
        return string.replace(new RegExp("[^"+ regexMatch +"]", "gi"), "");
      }

      if (element) {
        // Check the length of the input. Need to handle a few cases to get the selected size
        var selectedVariant = $('#ba_Size').val();
        if (selectedVariant && selectedVariant.match(/:/) && selectedVariant.match(/,$/)) {
          // If we're here we're probably looking at a bundle, in which case ba_Size is [ProductID]:[Variant],
          var productID = element.closest(".size-block").find("input.productID").val();
          var value = selectedVariant.match(new RegExp("\\b" + productID + ":([\\w\\._]+),"));
          if (value) {
            selectedVariant = match[1];
          } else {
            selectedVariant = "";
          }
        }

        // Now whatever the case, selectedVariant will either have the right value or no value
        if (selectedVariant) {
          var serviceID = element.data("service-id");
          var serviceLength = self.serviceData[serviceID]["LengthLimit"];
          if (typeof serviceLength === "object") {
            serviceLength = serviceLength[selectedVariant];
          }

          if (string.length > serviceLength) {
            return string.substr(0, serviceLength);
          }
        } else {
          $(".noSizeSelectedWarning").show();
          return false;
        }
      }

      return string;
    };

    this.init = function () {
      self.personalisationInputs = {};
      if($("#persn-relationships").val()){
        self.setRelationships(JSON.parse($("#persn-relationships").val()));
      }
      if($("#service-data").val()){
        self.setServiceData(JSON.parse($("#service-data").val()));
      }

      $(".persn-service__input")
        .on("change keyup input", self._onPersnInputChange)
        .each(function() {
          var serviceID = $(this).data("service-id");
          self.personalisationInputs[serviceID] = $(this);

          if (this.tagName.match(/select/i)) {
            var serviceOptions = self.serviceData[serviceID]["Options"] || [];

            // Select! Let's check for option images!
            var sodSelect = $(this).closest(".sod_select");
            for (var i = 0; i < serviceOptions.length; i++) {
              var optionData = serviceOptions[i];
              if (optionData && optionData["OptionImage"]) {
                sodSelect.find(".sod_option[data-value='"+ optionData["ID"] +"']").css({
                  "background-image"    : "url("+ skinJS_SiteURL + "/responsive-images/" + optionData["OptionImage"] +")",
                  "background-size"     : "40px 24px",
                  "background-position" : "88% 50%",
                  "background-repeat"   : "no-repeat"
                });
              }
            } // END for (var i in options.length)
          } // END if tagName.match(select)
        });
      $(".persn-service__input[type='text'], .persn-service__input[type='number']")
        .on("input change", function(e) {
          e.target.value = self._checkTextInput(e.target.value, e.target) || "";
        });
      self._updateInputsStatus();
    };

    // init!
    $(function() {
      self.init();

    });
  } // END persn()

  return new persn();
})();
