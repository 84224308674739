// *************************************************************************
//JS:lovell-home-page.js
// Lovell Rugby website functions specific to the home page
// (c) 2015 Lovell Rugby Limited

$(function() {
  // show modal signup form after delay
  if ($('#modal-competition').length) {
    setTimeout(function () {
      $('#modal-competition').modal();
      var competitionID = $('#modal-competition').data('competitionid');
      if (competitionID) {
        createCookie("COMPETITIONPOPUP-" + competitionID, '1', 30);
      }
    }, 5000);
  }

  // show generic modal if exists - else show newsletter popup
  if ($('#modal-generic').length) {
    var timeout = 5000;
    setTimeout(function () {
      $('#modal-generic').modal();
      createCookie('GENERICMODAL', 1, '+30d');
    }, timeout);
  } else {
    // show modal signup form after delay
    if ($('#modal-news-signup').length) {
      var timeout = 5000;
      setTimeout(function () {
        $('#modal-news-signup').modal();
        $('#navbar').removeClass('active');
        $('body').removeClass('navbar-open');
        $('.locale').removeClass('active');
        createCookie('NEWSLETTERPOPUP', 1, '+30d');
      }, timeout);
    }
  }

  // trigger the popup submit button on enter key of input text
  $('#modal-email').keydown(function(e) {
    if($(this).val().length) {
      if(e.which == 13) {
        $('#modal-signup-submit').click();
      }
    }
  });

});
