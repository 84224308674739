// *****************************************************************
//JS: lovell-checkout.js
// Lovell Rugby website functions for the checkout
// (c) 2017 Lovell Rugby Limited

$(function() {
  // saved address list in my account section
  // toggle the address on click of header
  $('.alt-address .address-ref h3').on('click', function() {
    $(this).closest('.alt-address').toggleClass('collapsed');
  });

  // hide the UK county selection box (created by the selectOrDie jquery plugin) if the original select is hidden
  if($('#UKCountySelector').is(':hidden')) {
    $('#UKCountySelector').closest('.sod_select').hide();
  }
  if($('#del_UKCountySelector').is(':hidden')) {
    $('#del_UKCountySelector').closest('.sod_select').hide();
  }

  // hide the Irish county selection box (created by the selectOrDie jquery plugin) if the original select is hidden
  if($('#irishCountySelector').is(':hidden')) {
    $('#irishCountySelector').closest('.sod_select').hide();
  }
  if($('#del_irishCountySelector').is(':hidden')) {
    $('#del_irishCountySelector').closest('.sod_select').hide();
  }

  // if a replacement select box has no text in the label, add it from the first option
  $('.sod_select').each(function() {
    if(!$('.sod_label', this).text()) {
      $('.sod_label').text($(this).find('.sod_option:first-child').text());
    }
  });

  // hide the USA states selection box (created by the selectBoxIt jquery plugin) if the original select is hidden
  if($('#USAStateSelector').is(':hidden')) {
    $('#USAStateSelector').closest('.sod_select').hide();
  }
  if($('#del_USAStateSelector').is(':hidden')) {
    $('#del_USAStateSelector').closest('.sod_select').hide();
  }

  // toggle visibility of registration form
  $('#btn-register').on('click', function() {
    $('.registration-form').slideToggle('fast');
  });

  // change continue button green when valid email address entered
  $('#register-email').on('input', function() {
    if(emailIsValid($('#register-email').val())) {
      $('#btn-continue-register').css('background-color', '#009a20');
    } else {
      $('#btn-continue-register').css('background-color', '#585858');
    }
  });

  // add the is-valid class to country selector as it loads up with GB selected
  setTimeout(function() {
    setFieldValid($('#addCountry'));
    setFieldValid($('#del_addCountry'));
  }, 1000);

  // login tabs (mobile only)
  $('.login-tab').on('click', function () {
    $('.login-tab').removeClass('active');
    $(this).addClass('active');
    $('.panel').removeClass('active');
    $('.' + $(this).data('panel')).addClass('active');
  });

  // select pay by card as default
  $(".field-control input").change();

}); // end doc ready