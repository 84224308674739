// *****************************************************************
//JS: lovell-newsletters.js
// Lovell Rugby website functions for newsletters
// (c) 2017 Lovell Rugby Limited

$(function () {

  //
  $(document).on("click", "#open-news-modal", function (e) {
    let from = $(this).data('from') || '';
    let CSRFToken = $(this).data('csrftoken') || '';
    showNewsletterSelectionModal(from, CSRFToken);
  });

  $(document).on("click", "#modal-signup-submit, #signup-submit", function (e) {
    let newsID = $(this).data('newsid') || '';
    let from = $(this).data('from') || '';
    let CSRFToken = $(this).data('csrftoken') || '';
    newsletterSignup(newsID, from, CSRFToken);
  });

  // clear the email signup input on click
  $("#email").on('click', function () {
    if ($(this).val() == 'Enter Email Address') {
      $(this).val('');
    }
  });
  
  // hide email input if email added to mailing list
  var targets = $('#newsSignupFeedback');

  // We now use MutationObserver to detect changes to newsSignupFeedback div (as DOMSubtreeModified is deprecated)
  var observer = new MutationObserver(function (mutations) {
    $('#newsSignupFeedback').removeClass('hidden');
    if ($('#newsSignupFeedback').find('div.success').length) {
      $('#newsSignupFeedback').removeClass('error');
      $('.form').hide(600);
      $('.preference-form').hide(600);
    }
    if ($('#newsSignupFeedback').find('div.error').length) {
      $('#newsSignupFeedback').addClass('error');
    }
  });

  // While there will only ever be one newsSignupFeedback, need to loop through the (single) instance of it, as observer expects a node.
  targets.each(function () {
    observer.observe(this, {
      attributes: true,
      childList: true,
      characterData: true
    });
  });
}); // end doc ready


function showNewsletterSelectionModal(from, CSRFToken) {
  var email = validateNewsletterEmail(from);

  if (!email) return;

  var newsLettersOutput;
  doAjaxAction(
    {
      action: 'GET_MERGED_SITE_NEWSLETTERS',
    },
    function (output) {
      newsLettersOutput = output;
      var modalHtml = getNewsletterSelectionModalHTML(newsLettersOutput);
      // Append modal to the body
      $('#newsletterModal').remove();
      $('body').append(modalHtml);

      // Show the modal
      $('#newsletterModal').modal('show');
      $('#confirm-newsletters').on('click', function (e) { confirmNewsletterSelection(email, CSRFToken); });
    }
  );
}

function getNewsletterSelectionModalHTML(newsletterModalContent) {
  var modalHtml =
    `<div class="modal fade" id="newsletterModal" tabindex="-1" role="dialog" aria-labelledby="Newsletter Signup" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                
                  
                  <div class="content-wrapper">
                    <div class="image">
                      <img src ="https://cdn.lovellsports.com/images/newsletters/LSP/our-newsletters.jpg" alt="Our Newsletters" class="img" />
                    </div>
                    <div class="content">
                      <h4 class="modal-title">Our Newsletters</h4>
                      <p>Please select the newsletters that you would like to subscribe to</p>` +
    newsletterModalContent +
    `
                      <div class="newsletter-agree field my-30-xs">
                        <label class="lovell-checkbox">
                          <input type="checkbox" id="newsletter-agree-terms" value="1">
                          <span class="agree-message">You will be signed up to our newsletters. Please tick to confirm that you understand.</span>
                        </label>
                      </div>
                      <div id="newsSignupFeedback"></div>
                      <div class="button-wrapper button-wrapper--centred">
                        <button id="confirm-newsletters" type="button" class="button mb-10-xs mb-0-sm">Confirm Choices</button>
                        <button type="button" class="button ml-10-sm button--secondary" data-dismiss="modal">Cancel</button>
                      </div>
                    </div>
                  </div>

                </div>
            </div>
        </div>
    </div>
  `;
  return modalHtml;
}

function confirmNewsletterSelection(email, CSRFToken) {
  var checkboxes = document.querySelectorAll(
    '.newsletter-signup__select input[type="checkbox"]'
  );

  if (!$('#newsletter-agree-terms').prop('checked')) {
    $('#newsSignupFeedback').html('<p class="alert alert--info">Please tick the box to confirm you understand that you will be signed up to our newsletter.</p>');
    return false;
  }

  // Loop through each checkbox
  checkboxes.forEach(function (checkbox) {
    // Check if the checkbox is checked
    if (checkbox.checked) {
      var data = {
        NewsID: checkbox.name,
        email: email,
        SiteCode: skinJS_SiteCode,
        subscribedFrom: 'email',
        CSRFToken: CSRFToken,
        agree: $('#newsletter-agree-terms').prop('checked'),
      };
      $('select.customerpreference').each(function () {
        data[$(this).attr('name')] = $(this).val();
      });
      doNewsletterSignup(data);
    }
  });
}

// AJAX - newsletter signup
function doNewsletterSignup(data) {
  // AJAX call for newsletter signup

  $.ajax({
    type: "POST",
    url: skinJS_SiteURL + "/cgi-bin/ajax-newslettersignup.cgi",
    data: data,
    error: function (XMLHttpRequest, textStatus, errorThrown) { },
    success: function (newData) {
      var jsonData = JSON.parse(newData);
      if (jsonData.message) {
        if (data.subscribedFrom == 'Popup') {
          if (newData.indexOf("modal-header") >= 0) {
            $('#modal-news-signup .modal-content').html(jsonData.message);
          } else {
            $('#modal-newsletter-response').html(jsonData.message);
          }
        } else {
          // default
          $('#newsSignupFeedback').html(jsonData.message);
        }
      }
      if (jsonData.analyticsNewsAction && jsonData.subscribedFrom) {
        analyticsNewsSubscribe(jsonData.analyticsNewsAction, jsonData.subscribedFrom);
      }
    }
  });
}

function validateNewsletterEmail(from) {
  var inputID;

  if (from == 'Popup') {
    inputID = 'modal-email';
  } else {
    inputID = 'email';
  }

  var email = document.getElementById(inputID).value;

  $('#signup-form .error-message').remove();

  if (!email || !emailIsValid(email)) {
    var error = '<div class="error-message" style="color: red; margin-top: 10px;">Please enter a valid email address</div>';
    $('#signup-form .form').after(error);
    return false;
  }
  return email;
}

function newsletterSignup(NewsID, from, CSRFToken) {
  var formOk = true;
  var name = '';
  var dob = '';
  var email = validateNewsletterEmail(from);

  if ($('#modal-news-signup input[name="name"]').length) {
    name = $('#modal-news-signup input[name="name"]').val();
  }

  if ($('input[name="newsletter-agree-terms"]').length) {
    if (!checkboxIsChecked('newsletter-agree-terms')) {
      setCheckboxError($('input[name="newsletter-agree-terms"]'), '');
      formOk = false;
    }
  }

  // if the user has entered a dob, validate it
  if ($('input#newsletter-dob').length) {
    dob = $('input#newsletter-dob').val();
    removeFieldError($('input#newsletter-dob'));
    if (dob.length) {
      if (!checkDateValid(dob)) {
        setFieldError($('input#newsletter-dob'), '');
        formOk = false;
      }
    }
  }

  var gender = '';
  if ($('input[name=gender]:checked').val() && $('input[name=gender]:checked').val() != 'null') {
    gender = $('input[name=gender]:checked').val();
  }

  if (!formOk || !email) {
    return;
  }

  var data = {
    NewsID: NewsID,
    email: email,
    name: name,
    dob: dob,
    gender: gender,
    SiteCode: skinJS_SiteCode,
    subscribedFrom: from,
    CSRFToken: CSRFToken,
    agree: $('#newsletter-agree-terms').prop('checked')
  };
  $('select.customerpreference').each(function () {
    data[$(this).attr('name')] = $(this).val();
  });

  doNewsletterSignup(data);
  return true;
}