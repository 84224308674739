// *****************************************************************
//JS: lovell-sliders.js
// Lovell Rugby website functions for slider panels
// (c) 2017 Lovell Rugby Limited

$(function () {
  hideSliderPlaceholders();
  applySliderButtonCSS();

  // feature slider
  if ($('.feature-slider').length) {
    $('.feature-slider').each(function (index, slider) {
      $(slider)
        .find('.swiper-button-next')
        .addClass('swiper-button-next-' + index);
      $(slider)
        .find('.swiper-button-prev')
        .addClass('swiper-button-prev-' + index);
      var swiper = new Swiper($(slider).find('.swiper')[0], {
        loop: false,
        spaceBetween: 10,
        slidesOffsetAfter: 10,
        slidesOffsetBefore: 10,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: '.swiper-button-next-' + index,
          prevEl: '.swiper-button-prev-' + index,
          enabled: false,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2.4,
            slidesPerGroup: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2.4,
            slidesPerGroup: 2,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3.4,
            slidesPerGroup: 3,
          },
          860: {
            slidesPerView: 4.4,
            slidesPerGroup: 4,
            slidesOffsetAfter: 10,
            slidesOffsetBefore: 10,
            spaceBetween: 10,
            navigation: {
              enabled: false,
            },
          },
          1080: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
            navigation: {
              enabled: true,
            },
          },
          1300: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
            navigation: {
              enabled: true,
            },
          },
        },
      });
    });
  }

  // feature slider
  if ($('.grid-panel--slider').length) {
    $('.grid-panel--slider').each(function (index, slider) {
      $(slider)
        .find('.swiper-scrollbar')
        .addClass('swiper-scrollbar-' + index);
      $(slider)
        .find('.swiper-scrollbar')
        .addClass('swiper-scrollbar-' + index);
      var swiper = new Swiper($(slider).find('.swiper')[0], {
        loop: false,
        spaceBetween: 10,
        scrollbar: {
          el: '.swiper-scrollbar-' + index,
          snapOnRelease: true,
          draggable: true,
          hide: false,
          enabled: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
          },
          480: {
            slidesPerView: 1.3,
            slidesPerGroup: 1,
          },
          600: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
          },
          860: {
            slidesPerView: 3.2,
            slidesPerGroup: 3,
            spaceBetween: 10,
          },
          1080: {
            slidesPerView: 3.2,
            slidesPerGroup: 3,
            spaceBetween: 20,
          },
          1300: {
            slidesPerView: 3.2,
            slidesPerGroup: 3,
            spaceBetween: 30,
          },
        },
      });
    });
  }

  // tabbed sliders *************************
  $(document).on('click', '.tabbed-sliders__header-item', function () {
    var activeItemIndex = $(this).index();
    // add the active class to both mobile and full tabs
    $('.tabbed-sliders__header-item').removeClass('is-active');
    $(this)
      .closest('.tabbed-sliders__header')
      .find('.tabbed-sliders__header-items')
      .each(function (e) {
        $('.tabbed-sliders__header-item', this)
          .eq(activeItemIndex)
          .addClass('is-active');
      });

    // add the active class to the slider panel
    $(this)
      .closest('.tabbed-sliders')
      .find('.tabbed-sliders__slider-item')
      .removeClass('is-active');
    $(this)
      .closest('.tabbed-sliders')
      .find('.tabbed-sliders__slider-item')
      .eq(activeItemIndex)
      .addClass('is-active');
  });

  // show the number of products in each slider next to the full (not mobile) slider tabs
  $('.tabbed-sliders__header-items--full .tabbed-sliders__header-item').each(
    function (index, element) {
      var itemCount = $('.tabbed-sliders__slider-item')
        .eq(index)
        .find('.slider__item').length;
      $(this).find('span').remove();
      $(this).append(
        ' <span class="tabbed-sliders__header-item-count">(' +
          itemCount +
          ')</span>'
      );
    }
  );
}); // end doc ready

function applySliderButtonCSS() {
  $('[data-slider-button-bg]').each(function (e) {
    $(this)
      .find('.slider__item-button--view-product')
      .css('background-color', $(this).attr('data-slider-button-bg'));
  });
}

function hideSliderPlaceholders() {
  // hides the FEATURESLIDER placeholder for any sliders that did not find any products
  $('.slider-panel').each(function () {
    if ($(this).text().indexOf('FEATURESLIDER') >= 0) {
      $(this).addClass('hidden');
    } else {
      $(this).removeClass('hidden');
    }
  });
}
