$(function () {

  $(".header-currency .current-currency").on('click', function () {
    $(".header-currency .locale").toggleClass("active");
  });
  $(".header-currency .locale .currency-options__option").on('click', function () {
    // item may be a link to foreign site version
    if ($(this).data("value").indexOf("https://") >= 0) {
      window.location.href = $(this).data("value");
    }

    if (
      $(this).data("value") !==
      $(".header-currency .current-currency .currency-display").data("value")
    ) {
      changeCurrency($(this).data("value"));
      $(".header-currency .locale").toggleClass("active");
    }
  });
  // move selected currency to top of list
  $(".header-currency .locale .currency-options__option").each(function () {
    if (
      $(this).data("value") ==
      $(".header-currency .current-currency .currency-display").data("value")
    ) {
      $(this).remove().prependTo(".header-currency .locale .currency-options");
    }
  });

  // change field inputs to new material design style
  if ($(".checkout-panel").length || $(".lovell-form").length) {
    $(".field").each(function (i, e) {
      var fieldName = $(e).find(".field-name").text();
      var inputSelector =
        'input[type="number"],input[type="text"],input[type="email"],input[type="tel"],input[type="password"]';

      fieldName = fieldName.replace(":", "");
      $("<label>" + fieldName + "</label>").insertAfter(
        $(e).find(inputSelector)
      );
      $(
        '<label for="' +
          $(e).find("textarea").attr("id") +
          '">' +
          fieldName +
          "</label>"
      ).insertAfter($(e).find("textarea"));
      $("<label>" + fieldName + "</label>").insertAfter(
        $(e).find(".sod_label")
      );
      $(e).find(inputSelector).attr("placeholder", " ");
    });
  }

  // focus the input if the label is clicked
  $(".field-control label").on("click", function () {
    $(this)
      .closest(".field-control")
      .find(
        'input[type="text"],input[type="email"],input[type="tel"],input[type="number"]'
      )
      .focus();
  });

  $(document).on("click", ".main-nav-currency a", function () {
    $(".locale").toggleClass("active");
  });
  $(".locale .currency-options__option").on('click', function () {
    changeCurrency($(this).data("value"));
  });

  // remove the account and currency panels from mobile menu when navigating down into menu items
  if (getDeviceType() == "mobile" || getDeviceType() == "tablet") {
    $(document).on(
      "click",
      "a.mainNav:not(.active), div.mainNav:not(.active)",
      function (e) {
        if (
          $(this).parent().hasClass("main-nav-your-account") ||
          $(this).parent().hasClass("main-nav-currency") ||
          !$(this).parent().find('div .dropdown-menu').children().length
        ) {
          return;
        }
        // click on one of the first level nav items
        $(".navbar-title .navbar-title__text").hide();
        $(".navbar-title").find("a.mainNav").remove();
        $(".navbar-title").find("div.mainNav").remove();
        $("a.mainNav.active").clone().appendTo(".navbar-title");
        $("div.mainNav.active").clone().appendTo(".navbar-title");
        $("ul.nav a.mainNav.active").hide();
        $("ul.nav div.mainNav.active").hide();
        $(".main-nav-your-account,.main-nav-currency").hide();
        $(".navbar-back").show();
      }
    );
    $(document).on(
      "click",
      ".navbar-title a.mainNav, .navbar-title div.mainNav",
      function (e) {
        // click on nav title - 2nd level
        e.preventDefault();
        $("ul.nav a.mainNav.active, ul.nav div.mainNav.active").click().show();
        $(".navbar-title a.mainNav").remove();
        $(".navbar-title div.mainNav").remove();
        $(".navbar-title .navbar-title__text").show();
        $("#megaMenuTabs").css("height", "calc(90vh - 60px)");
        $(".navbar-back").hide();
      }
    );
  }

  $("ul.nav li").on(
    "click",
    "div ul.dropdown-menu li.dropdown-header",
    function (e) {
      // click on the 2nd level nav items
      if (getDeviceType() == "mobile" || getDeviceType() == "tablet") {
        $(".navbar-title .navbar-title__text").hide();
        $(".navbar-title").find("a.mainNav").remove();
        $(".navbar-title").find("div.mainNav").remove();
        $("ul.dropdown-menu li.dropdown-header.current")
          .clone()
          .appendTo(".navbar-title");
        $(".navbar-title li.dropdown-header.current .back-button").wrap(
          '<div></div>'
        );
        $("ul.dropdown-menu li.dropdown-header.current").hide();
      }
    }
  );
  $(document).on("click", ".navbar-title .dropdown-header", function (e) {
    // click on nav title (back)
    $("ul.nav li div ul.dropdown-menu li.dropdown-header.current")
      .click()
      .show();
    $("a.mainNav.active").clone().appendTo(".navbar-title");
    $("div.mainNav.active").clone().appendTo(".navbar-title");
    $("ul.nav a.mainNav.active").hide();
    $("ul.nav div.mainNav.active").hide();
    $(".navbar-title .dropdown-header").remove();
  });

  $(document).on("click", ".currency-select-option", function () {
    changeCurrency($(this).data("value"));
  });

  // **********************************************
  // navbar open/close
  // **********************************************
  var scrollDistance;
  $(document).on('click', "#menu-open", function () {
    scrollDistance = $(window).scrollTop();
    $("#navbar").addClass("active");
    $("body").addClass("navbar-open");
    $("body").css("top", scrollDistance * -1);
    // Stop body from scrolling while in the mobile menu, as we were having some issues with the body scrolling when users attempted to scroll the menu overlay.
  });

  $(document).on("click", ".navbar-close, .navbar-mask", function (event) {
    event.preventDefault();
    $("#navbar").removeClass("active");
    $("body").removeClass("navbar-open");
    $(".locale").removeClass("active");
    $("body").css("top", "");
    $(window).scrollTop(scrollDistance);
  });

  $(document).on("click", ".navbar-back", function (event) {
    // get the link from the a.mainNav and attach to the navbar-back button
    $(".navbar-title a.mainNav").click();
    $(".navbar-title div.mainNav").click();
    $(".navbar-title .dropdown-header").click();
  });

  // Opening the keyboard from the iOS app, causes the header to loose its fixed
  // position and stay in the same place in the middle of the page when scrolled.
  // This pushes the header to the top of the screen whilst the keyboard is open
  // and returns it to a fixed position when closed.
  $(".ios input, .ios select").on("focus", function () {
    $(".header-mobile, #search-bar").css("position", "absolute");
  });

  $(".ios input, .ios select").on("blur", function () {
    $(".header-mobile, #search-bar").css("position", "fixed");
  });

  $("body").on('click', function (e) {
    if (!$(e.target).closest(".header-currency").length) {
      $(".header-currency .locale").removeClass("active");
    }
    if (!$(e.target).closest(".filter-item").length) {
      $(".showQuickSearchItem").removeClass("active");
    }
    if (
      !$(e.target).closest(".chooser-filters").length &&
      $(".chooser-filters").css("left") == "0px"
    ) {
      closeFilters();
      e.preventDefault();
    }
  });

  $("#header-basket, #basket-total-items").on('mouseover', function (e) {
    basketHover(e);
  });
  $("#basket-total-items").on("click", function (e) {
    $(document).find(".mini-basket-button-basket").click();
  });

  $("#header-basket").mouseleave(function () {
    $("#mini-basket-panel").removeClass("active");
  });

  $("#search-bar").on("click", ".close-search", function () {
    $("#search-bar").removeClass("active");
  });

  // remove 'Now' from slider price
  $(".slider__item-cost.now").each(function (e) {
    $(this).text($(this).text().replace("Now: ", ""));
  });

  // add account and currency options to mobile menu
  $(window)
    .on("resize", adjustMenu)
    .trigger("resize");
      
  function adjustMenu() {
    if (getDeviceType() == "mobile" || getDeviceType() == "tablet") {
      var userLink =
        '<li class="main-nav-your-account"><a class="mainNav" href="' +
        skinJS_SiteURL +
        '/login"><span class="mainNavText">Your Account</span>' +
        "</a></li>";
      $("nav.navbar #megaMenuTabs ul.nav .main-nav-your-account").remove();
      $("nav.navbar #megaMenuTabs ul.nav").append(userLink);

      if (skinJS_SiteCode == 'LR' || skinJS_SiteCode == 'LS') {
        var blogLink =
          '<li class="main-nav-blog"><a class="mainNav" href="' +
          skinJS_SiteURL +
          '/blog"><span class="mainNavText">Blog</span>' +
          '</a></li>';
        $('nav.navbar #megaMenuTabs ul.nav .main-nav-blog').remove();
        $('nav.navbar #megaMenuTabs ul.nav').append(blogLink);
      }

      var currentcurrency = $(".header-currency .currency-display").first().text();
      var currentCurrencyFlag = $(".header-currency .currency-display").data(
        "value"
      );
      var currencyoptions = "";
      $(".header-currency .locale .sod_select .sod_list .sod_option").each(
        function () {
          currencyoptions +=
            '<li class="currency-select-option shopAll" data-value="' +
            $(this).data("value") +
            '"><a href="#">' +
            $(this).text() +
            "</a></li>";
        }
      );
      var currencyLink =
        '<li class="main-nav-currency">' +
        '<a href="#" class="mainNav">' +
        "" +
        '<span class="mainNavText"><span class="currency-flag"><img src="' +
        skinJS_SiteURL +
        "/images/flag-icons/" +
        currentCurrencyFlag +
        '.png" alt="' +
        currentcurrency +
        ": Change currency" +
        '"/></span> ' +
        currentcurrency +
        "</span>" +
        "</a>" +
        '<div id="tab11">' +
        '<ul id="menu11" class="dropdown-menu" role="menu">' +
        currencyoptions +
        "</ul" +
        "</div>" +
        "</li>";

      $("nav.navbar #megaMenuTabs ul.nav .main-nav-currency").remove();
      $("nav.navbar #megaMenuTabs ul.nav").append(currencyLink);

    } else {
      $("nav.navbar ul.nav li.main-nav-your-account").remove();
      $('nav.navbar ul.nav li.main-nav-blog').remove();
      $("nav.navbar ul.nav li.main-nav-currency").remove();
      $('ul.nav li').removeClass('hide');

      // remove any top level links that are making the navbar too wide for it's container on desktop
      var menuFitsContainer = false;
      var navWidth = 0;
      var containerWidth = 0;
      while (!menuFitsContainer) {
        // get the width of the combined menu items
        navWidth = 0;
        $('ul.nav li:visible').each(function () {
          navWidth += $(this).outerWidth();
        });
        // get the width of the container
        containerWidth = $('#navbar').innerWidth();
       
        // get the number of menu items
        listLength = $('ul.nav li:visible').length;
        if (navWidth > (containerWidth - 40)) {
          $('ul.nav li')
            .eq(listLength - 2)
            .addClass('hide');
        } else {
          menuFitsContainer = true;
        }
      }
    }
  }

  function basketHover(e) {
    prepareBasketDisplay("#mini-basket-panel");
    $("#mini-basket-panel").addClass("active");
    // Close live search
    closeLiveSearch();
    $(".header-currency .locale").removeClass("active");
  }

  // stop hover state from sticking when using touch devices
  $(".splash-page .tile, .splash-page .full-tile").on("touchstart", function (
    e
  ) {
    // Assume touch device until a mouse is used
    $(this).removeClass("tile-pseudo-hover");
    $(this).addClass("tile-touch-hover");
  });
  $(".splash-page .tile, .splash-page .full-tile").on("touchend", function (e) {
    $(this).removeClass("tile-touch-hover");
  });
  $(".splash-page .tile, .splash-page .full-tile").on("mouseenter", function (
    e
  ) {
    // Assume mouse until touch device is used
    $(this).removeClass("tile-touch-hover");
    $(this).addClass("tile-pseudo-hover");
  });
  $(".splash-page .tile, .splash-page .full-tile").on("mouseleave", function (
    e
  ) {
    $(this).removeClass("tile-pseudo-hover");
  });

  // basket
  basketConfig();

  //scrolls to top of chooser page when navigating pages.
  $(".container").on("click", "a.pgr", function (e) {
    if (!$(this).hasClass("showall")) {
      $("html, body").animate({ scrollTop: 0 }, 1000);
    }
  });
});

// adds "/" between day/month/year as the user types.
function dateTextFormat(e) {
  var dateField = $(e.target);
  dateField.val(
    dateField
      .val()
      .replace(/^(\d\d)(\d)$/g, "$1/$2")
      .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
      .replace(/[^\d\/]/g, "")
  );
}
